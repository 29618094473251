














































































































































































::v-deep .el-table__fixed-right-patch {
  display: none;
}
.script-management-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
  .script-title {
    margin: 19px 0;
  }
  .script-main {
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .main-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
}
